import Link from "next/link";
import { Dispatch, SetStateAction } from "react";
import { MenuType, OptionsType } from "../../utils/baseTypes";
import { getRelativePathFromLink } from "../../utils/url";
import { CustomLink } from "../Global/CustomLink";

interface MenuProps {
  menu: MenuType;
  showCompany?: boolean;
  options?: OptionsType;
  setShowConsentManagement?: Dispatch<SetStateAction<boolean>>;
  menuName?: "touch-top" | "bottom" | "top";
}

export const Menu = ({
  menu,
  showCompany,
  options,
  setShowConsentManagement,
  menuName,
}: MenuProps) => {
  const currentYear = new Date(Date.now()).getFullYear();
  return (
    <ul
      id="legal-menu"
      role="menubar"
      aria-label="legal-menu"
      className="legal-menu"
    >
      {showCompany && (
        <li role="presentation">
          &copy;&nbsp;ALEKS &amp; SHANTU&nbsp;{currentYear}{" "}
        </li>
      )}
      {menu.items.map((item, index) => (
        <li key={index} className="m-0" role="none">
          <Link
            prefetch={false}
            href={getRelativePathFromLink(item.url, item.type)}
            target={item.target}
            className="legal menu-item is-uppercase"
            id={`${menu.name}-menuitem-${index}-${menuName ?? ""}`}
            role="menuitem"
          >
            <span>{item.title}</span>
          </Link>
        </li>
      ))}
      {options != null && setShowConsentManagement != null ? (
        <li role="presentation" className="m-0">
          <a
            role="menuitem"
            href={"#"}
            id="menuitem-privacysettings"
            className="legal menu-item is-uppercase"
            onClick={(e) => {
              e.preventDefault();
              setShowConsentManagement(true);
            }}
          >
            {/* FIXME: This should be a label */}
            <span>{"COOKIE CONSENT"}</span>
          </a>
        </li>
      ) : null}
    </ul>
  );
};
