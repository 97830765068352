import Link from "next/link";
import { useRouter } from "next/router";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { getRelativePathFromLink } from "../../../utils/url";
import { useMediaQuery } from "../../../hooks/useMediaQuery";
import ArrowIcon from "../../../images/arrow.svg";
import { MenuItemType, PageType } from "../../../utils/baseTypes";
import { MenuItemActiveState } from "./MainMenu";
import { isMenuItemCurrentPage, isMenuItemOrChildCurrentPage } from "../utils";

interface MenuItemProps {
  index: number;
  element: MenuItemType;
  isActive?: MenuItemActiveState;
  setIsActive: Dispatch<SetStateAction<MenuItemActiveState | undefined>>;
  page: PageType;
  isFocused: boolean;
  totalMainItems: number;
  setFocusedMainIndex: Dispatch<SetStateAction<number>>;
  onFocus: (index: number) => void;
  registerLinkRef: (index: number, ref: HTMLAnchorElement) => void;
}

const HIGHTLIGHT_CURRENT_PAGE = true;
const HIGHLIGHT_CURRENT_PAGES_PARENTS = true;

export const MenuItem = ({
  element,
  index,
  isActive,
  setIsActive,
  page,
  isFocused,
  onFocus,
  registerLinkRef,
  setFocusedMainIndex,
  totalMainItems,
}: MenuItemProps) => {
  const router = useRouter();
  const liRef = useRef<HTMLLIElement>(null);
  const linkRef = useRef<HTMLAnchorElement>(null);
  const hasChildren = element.child_items && element.child_items.length > 0;

  const isMobile = useMediaQuery("(max-width: 767px)");

  useEffect(() => {
    // ? || navigator.msMaxTouchPoints <-- This was in the setState, where does it come from?
    const checkIfClickedOutside = () => {
      if (
        liRef.current &&
        isActive?.toggle &&
        isActive.currentIndex === index
      ) {
        if (liRef.current.matches(":hover") === false) {
          setIsActive({ toggle: false, currentIndex: null });
        }
      }
    };
    if (!isMobile) {
      isActive?.toggle && isActive.currentIndex === index
        ? document.addEventListener("mousedown", checkIfClickedOutside)
        : document.removeEventListener("mousedown", checkIfClickedOutside);
    } else {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    }
    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isActive, setIsActive, index, isMobile]);

  useEffect(() => {
    const handleKeyDownMain = (e: KeyboardEvent) => {
      switch (e.key) {
        case "ArrowLeft":
          e.preventDefault();
          setFocusedMainIndex((prev) =>
            prev > 0 ? prev - 1 : totalMainItems - 1
          );
          break;
        case "ArrowRight":
          e.preventDefault();
          setFocusedMainIndex((prev) => (prev + 1) % totalMainItems);
          break;
        default:
          break;
      }
    };

    const mainMenuElement = linkRef.current;

    if (mainMenuElement) {
      mainMenuElement.addEventListener("keydown", handleKeyDownMain);
    }

    return () => {
      if (mainMenuElement) {
        mainMenuElement.removeEventListener("keydown", handleKeyDownMain);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFocused]);

  useEffect(() => {
    if (linkRef.current) {
      registerLinkRef(index, linkRef.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);

  const isCurrentPage = HIGHTLIGHT_CURRENT_PAGE
    ? HIGHLIGHT_CURRENT_PAGES_PARENTS
      ? isMenuItemOrChildCurrentPage(element, page)
      : isMenuItemCurrentPage(element, page)
    : false;

  return (
    <li
      role={hasChildren ? "presentation" : "none"}
      ref={liRef}
      className={`${
        isActive?.toggle && isActive.currentIndex === index ? "is-active" : ""
      } ${element.classes[0] === "is-lang" ? "is-lang" : ""}
        ${element.classes[0] === "has-sub" ? "has-sub" : ""} ${
        hasChildren ? "has-sub" : ""
      }
        ${
          router.asPath + "/" === getRelativePathFromLink(element.url) ||
          router.asPath === getRelativePathFromLink(element.url) ||
          (router.asPath.startsWith("/digitalagentur/") &&
            getRelativePathFromLink(element.url) === "/digitalagentur/") || //select digitalagentur menu item for every digitalagentur subpage
          (router.asPath.startsWith("/project/") &&
            getRelativePathFromLink(element.url) === "/portfolio/") //select portfolio menu item for every project (sub)page
            ? "is-current"
            : ""
        }
        `}
    >
      {!hasChildren && (
        <Link
          ref={linkRef}
          prefetch={false}
          href={getRelativePathFromLink(element.url)}
          id={`main-menu-${index + 1}`}
          aria-haspopup="false"
          {...(isCurrentPage && { "aria-current": "page" })}
          className={`main-menu-item 
            ${element.classes[0] === "is-lang" ? "is-lang" : ""} ${
            element.classes[0] === "has-sub" ? "has-sub" : ""
          } ${
            router.asPath + "/" === getRelativePathFromLink(element.url) ||
            router.asPath === getRelativePathFromLink(element.url)
              ? "is-current"
              : ""
          }`}
          onFocus={() => onFocus(index)}
          tabIndex={isFocused ? 0 : -1}
        >
          {element.title}
        </Link>
      )}

      {/* temperory added incase if we have submen add code below  */}
      {hasChildren && <span>{element.title}</span>}
    </li>
  );
};
