import { MenuItemType, PageType } from "../../utils/baseTypes";

/**
 * Checks if a menu item or any of its children is selected.
 * @param item - The menu item to check.
 * @param currentSubmenu - The currently selected submenu.
 * @returns `true` if the item or one of its children is selected, `false` otherwise.
 */
export const isMenuOrChildMenuSelected = (
  item: MenuItemType,
  currentSubmenu: MenuItemType | undefined
): boolean => {
  if (currentSubmenu?.ID === item.ID) return true;
  return (
    item.child_items?.some((child) =>
      isMenuOrChildMenuSelected(child, currentSubmenu)
    ) || false
  );
};

/**
 * Checks if a menu item corresponds to the current page.
 * @param item - The menu item to check.
 * @param pageData - The current page data.
 * @returns `true` if the item corresponds to the current page, `false` otherwise.
 */
export const isMenuItemCurrentPage = (
  item: MenuItemType,
  pageData: PageType
): boolean => pageData.id === Number(item.object_id);

/**
 * Checks if a menu item or any of its children corresponds to the current page.
 * @param item - The menu item to check.
 * @param pageData - The current page data.
 * @returns `true` if the item or one of its children corresponds to the current page, `false` otherwise.
 */
export const isMenuItemOrChildCurrentPage = (
  item: MenuItemType,
  pageData: PageType
): boolean => {
  if (isMenuItemCurrentPage(item, pageData)) return true;
  return (
    item.child_items?.some((child) =>
      isMenuItemOrChildCurrentPage(child, pageData)
    ) || false
  );
};

/**
 * Checks if a menu item or any of its children corresponds to the current page.
 * @param item - The menu item to check.
 * @param pageData - The current page data.
 * @returns `true` if the item or one of its children corresponds to the current page, `false` otherwise.
 */
export const isChildCurrentPage = (
  item: MenuItemType,
  pageData: PageType
): boolean => {
  return (
    item.child_items?.some((child) =>
      isMenuItemOrChildCurrentPage(child, pageData)
    ) || false
  );
};
