import React, { ComponentProps, forwardRef, Ref } from "react";

interface SectionProps extends ComponentProps<"section"> {
  containerClassName?: string;
  columnsClassName?: string;
  disableColumns?: boolean;
  disableContainer?: boolean;
  isClipped?: boolean;
  children: React.ReactNode;
}

/**
 * Standard Section includes 'section', 'container' and 'columns' class.
 * Use with property 'disableColumns' to avoid columns class.
 * Use with property 'disableContainer' to avoid 'container' class.
 * Always set 'sectionClassName' to enable specific styling.
 */
export const Section = forwardRef(
  (
    {
      columnsClassName,
      containerClassName,
      disableColumns,
      disableContainer,
      isClipped = true,
      children,
      ...props
    }: SectionProps,
    ref: Ref<HTMLElement>
  ) => {
    let sectionJSX;

    if (disableContainer) {
      sectionJSX = disableColumns ? (
        children
      ) : (
        <div className={`columns ${columnsClassName ?? ""}`}>{children}</div>
      );
    } else {
      sectionJSX = (
        <div className={`container ${containerClassName ?? ""}`}>
          {disableColumns ? (
            children
          ) : (
            <div className={`columns ${columnsClassName ?? ""}`}>
              {children}
            </div>
          )}
        </div>
      );
    }

    return (
      <section
        id={props.id}
        ref={ref}
        {...props}
        className={`section ${props.className} ${
          isClipped ? "is-clipped" : ""
        }`}
      >
        {sectionJSX}
      </section>
    );
  }
);

Section.displayName = "Section";
