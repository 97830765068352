import { useEffect, useState } from "react";
import { motion, useMotionValueEvent, useScroll } from "framer-motion";

import { Logo } from "./Logo/Logo";
import { MainMenu } from "./Menu/MainMenu";
import { MenuType, OptionsType, PageType } from "../../utils/baseTypes";
import { getRelativePathFromLink } from "../../utils/url";
import Aunds from "./Aunds";
import AundsLogo from "./Aunds/TouchLogo.svg";
const Hamburger = dynamic(() => import("./Hamburger"), { ssr: false });

// import LanguageSeparator from "../../images/navbar/aunds-language-sererator.svg";
import useWindowSize from "../../hooks/useWindowSize";
import { defaultEase } from "../../utils/transition";
import { LanguageToggle } from "./Language/LanguageToggle";
import { useRouter } from "next/router";
import { sanitize } from "isomorphic-dompurify";
import dynamic from "next/dynamic";
import Link from "next/link";

interface NavbarProps {
  menu: MenuType;
  options: OptionsType;
  className?: string;
  defaultBg?: boolean;
  page: PageType;
}

const variants = {
  open: { opacity: 1, y: 0, height: "100%", zIndex: -1 },
  closed: { opacity: 0, y: "-100%", height: "0px" },
};

export const Navbar = ({ options, menu, page }: NavbarProps) => {
  const { width } = useWindowSize();

  const [reachedTop, setReachedTop] = useState<boolean>(false);
  const [animation, setAnimation] = useState<"closed" | "open">(
    width <= 1023 ? "closed" : "open"
  );

  console.log("animation", animation);
  const [hoverContact, setHoverContact] = useState<boolean>(false);

  const title = options.acf.titel;
  const position = options.acf.funktion;

  const { scrollY } = useScroll();

  const scrollToggleNav = (latest: number) => {
    if (window.innerWidth > 1023) {
      if (latest <= 200 && scrollY.getPrevious() - latest > 0) {
        setAnimation("open");
        setReachedTop(true);
      } else {
        setAnimation("closed");
        setReachedTop(false);
      }
    }
  };

  useEffect(() => {
    const main = document.getElementById("main");
    if (main) {
      if (animation === "open") {
        main.classList.add("enable-margin");
      } else if (animation === "closed") {
        main.classList.remove("enable-margin");
      }
    }
  }, [animation]);

  useMotionValueEvent(scrollY, "change", (latest: number) => {
    scrollToggleNav(latest);
  });

  useEffect(() => {
    if (width <= 1023) {
      setAnimation("closed");
    } else {
      setAnimation("open");
    }
  }, [width]);

  const { events } = useRouter();

  useEffect(() => {
    const handler = () => {
      if (window.innerWidth < 1023) {
        setAnimation("closed");
      }
    };
    events.on("routeChangeComplete", handler);
    return () => {
      events.off("routeChangeComplete", handler);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <header role="banner" className={animation === "open" ? "" : "hidden-menu"}>
      <nav role="navigation" className="navbar-custom">
        <div className="navbar-top">
          <div className="nav-left">
            <motion.button
              className="hamburger-button"
              aria-label="Öffnet und schliesst das Hauptmenü"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setAnimation((prev) => (prev === "open" ? "closed" : "open"));
              }}
            >
              {" "}
              <Hamburger
                animation={animation}
                reachedTop={reachedTop}
                setReachedTop={setReachedTop}
                showHamburger={true}
              />
            </motion.button>

            <Logo options={options} />

            {title ||
              (position && (
                <div className="logo-text">
                  {title && <h3>{title}</h3>}
                  {position && <p>{position}</p>}
                </div>
              ))}
          </div>
          <div className="nav-right is-hidden-mobile">
            <LanguageToggle page={page} type="clickable" />
            <div className="text is-hidden-touch last">
              <span
                dangerouslySetInnerHTML={{
                  __html: sanitize(options.acf.header_text),
                }}
              ></span>
            </div>
            <div id={"navbar-contact"} className="contact is-hidden-mobile">
              <Link
                className="is-capitalized"
                href={options.acf.header_contact_link.url}
                target={options.acf.header_contact_link.target}
                onMouseEnter={() => {
                  setHoverContact(true);
                }}
                onMouseLeave={() => {
                  setHoverContact(false);
                }}
                onFocus={() => {
                  document
                    .querySelector("#navbar-contact")
                    ?.classList.add("is-focused");
                }}
                onBlur={() => {
                  document
                    .querySelector("#navbar-contact")
                    ?.classList.remove("is-focused");
                }}
              >
                {options.acf.header_contact_link.title} &nbsp;
                <Aunds
                  width={40}
                  height={30}
                  color={"#fff"}
                  isHover={hoverContact}
                />
              </Link>
            </div>
          </div>
        </div>
        <motion.div
          animate={animation}
          variants={variants}
          transition={{
            easings: defaultEase,
          }}
          initial={width <= 1023 ? "closed" : "open"}
        >
          <div className="navbar-bottom-wrapper">
            <div className="navbar-bottom">
              <MainMenu page={page} menu={menu} setAnimation={setAnimation} />
              <div>
                <LanguageToggle
                  page={page}
                  type="clickable"
                  extraClasses="is-hidden-desktop"
                />
                <div className="text is-hidden-desktop">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: sanitize(options.acf.header_text),
                    }}
                  ></span>
                </div>
                <div className="contact is-hidden-desktop">
                  <Link
                    className="is-capitalized"
                    href={options.acf.header_contact_link.url}
                    target={options.acf.header_contact_link.target}
                    prefetch={false}
                  >
                    {options.acf.header_contact_link.title} &nbsp;
                    <AundsLogo width={40} height={30} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </motion.div>
      </nav>
    </header>
  );
};
