import { MenuItem } from "./MenuItem";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { MenuType, PageType } from "../../../utils/baseTypes";
import useUpdateEffect from "../../../hooks/useUpdateEffect";

interface MainMenuProps {
  menu: MenuType;
  page: PageType;
  setAnimation: Dispatch<SetStateAction<"closed" | "open">>;
}

export type MenuItemActiveState = {
  toggle: boolean;
  currentIndex: number | null;
};

export const MainMenu = ({ menu, page, setAnimation }: MainMenuProps) => {
  useEffect(() => {
    function handleResize() {
      setIsActive({
        toggle: false,
        currentIndex: null,
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isActive, setIsActive] = useState<MenuItemActiveState>();
  const [focusedMainIndex, setFocusedMainIndex] = useState<number>(0);
  const linkRefs = useRef<HTMLAnchorElement[]>([]);
  const elements = menu.items || [];

  // Focus the active menu item when index changes
  useUpdateEffect(() => {
    if (linkRefs.current[focusedMainIndex]) {
      setAnimation("open");
      linkRefs.current[focusedMainIndex].focus();
    } else {
      setAnimation("closed");
    }
  }, [focusedMainIndex]);

  const registerLinkRef = (index: number, ref: HTMLAnchorElement) => {
    linkRefs.current[index] = ref;
  };

  useEffect(() => {
    const handleFocusIn = (event: FocusEvent) => {
      console.log(event.target);
      if (
        event.target instanceof HTMLElement &&
        event.target.classList.contains("main-menu-item")
      ) {
        setAnimation("open");
      }
    };

    const handleFocusOut = (event: FocusEvent) => {
      const relatedTarget = event.relatedTarget as HTMLElement | null;

      if (
        !relatedTarget ||
        !(
          relatedTarget instanceof HTMLElement &&
          relatedTarget.classList.contains("main-menu-item")
        )
      ) {
        setFocusedMainIndex(0);
      }
    };

    document.addEventListener("focusin", handleFocusIn);
    document.addEventListener("focusout", handleFocusOut);
    return () => {
      document.removeEventListener("focusin", handleFocusIn);
      document.removeEventListener("focusout", handleFocusOut);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ul className="main-menu" role="menubar" aria-label="Hauptmenü">
      {elements.map((element, i) => {
        return (
          <MenuItem
            element={element}
            index={i}
            key={i}
            isActive={isActive}
            setIsActive={setIsActive}
            page={page}
            isFocused={focusedMainIndex === i}
            totalMainItems={elements.length}
            setFocusedMainIndex={setFocusedMainIndex}
            onFocus={(index: number) => setFocusedMainIndex(index)}
            registerLinkRef={registerLinkRef}
          />
        );
      })}
    </ul>
  );
};
