import React from "react";
import { useRouter } from "next/router";

/**
 * A custom link component that disables Next.js prefetching
 * while supporting client-side navigation, shallow routing, and scroll behavior.
 */

interface LinkNoPrefetchProps {
  href: string;
  as?: string;
  children?: React.ReactNode;
  dangerouslySetInnerHTML?: { __html: string | TrustedHTML };
  className?: string;
  shallow?: boolean;
  scroll?: boolean;
  onClick?: () => void;
}

export const LinkNoPrefetch = React.forwardRef<
  HTMLAnchorElement,
  LinkNoPrefetchProps
>(
  (
    {
      href,
      as,
      children,
      className,
      shallow = false,
      scroll = true,
      onClick,
      dangerouslySetInnerHTML,
    },
    ref
  ) => {
    const router = useRouter();

    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
      // Allow default behavior for middle click, cmd/ctrl+click (new tab)
      if (
        event.defaultPrevented ||
        event.button !== 0 || // Left-click only
        event.metaKey || // Cmd (Mac)
        event.ctrlKey || // Ctrl (Windows/Linux)
        event.shiftKey ||
        event.altKey
      ) {
        return;
      }

      event.preventDefault(); // Prevent full-page reload

      if (onClick) {
        onClick(); // Execute custom click handler
      }

      router.push(href, as, { shallow, scroll });
    };

    return (
      <a
        ref={ref}
        href={as || href}
        onClick={handleClick}
        className={className}
        dangerouslySetInnerHTML={children ? undefined : dangerouslySetInnerHTML}
      >
        {children}
      </a>
    );
  }
);

LinkNoPrefetch.displayName = "LinkNoPrefetch";
